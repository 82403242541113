<template>
  <div class="pp">
    <button type="button" class="pp__close-btn r-btn" @click="close()">
      <svg-icon width="16" height="16" name="x" class="btn-icon" />
    </button>

    <swiper
      :modules="[Controller, Navigation, Mousewheel, Lazy]"
      :controller="{ control: secondSwiper }"
      :slidesPerView="1"
      :centeredSlides="true"
      :mousewheel="true"
      :preloadImages="false"
      :lazy="true"
      :spaceBetween="0"
      :speed="400"
      :navigation="{
        nextEl: '.swiper__button-next',
        prevEl: '.swiper__button-prev'
      }"
      @swiper="setFirstSwiper"
      class="swiper__slider-images"
    >
      <swiper-slide
        class="swiper__slide"
        v-for="(slide, index) in slds.images"
        :key="index"
      >
        <div class="swiper__slide-container">
          <img class="swiper__slider-img swiper-lazy" :data-src="slide" />
          <div class="swiper-lazy-preloader"></div>
        </div>
      </swiper-slide>

      <button type="button" class="swiper__button-prev r-btn">
        <svg-icon width="14" height="10" name="arrow_left" class="btn-icon" />
      </button>
      <button type="button" class="swiper__button-next r-btn">
        <svg-icon width="14" height="10" name="arrow_right" class="btn-icon" />
      </button>
    </swiper>

    <div class="pp__thumbs-container">
      <swiper
        class="swiper__thumbs"
        :modules="[Controller, Mousewheel]"
        :controller="{ control: firstSwiper }"
        :mousewheel="true"
        :centeredSlides="true"
        :slidesPerView="'auto'"
        :spaceBetween="0"
        :speed="400"
        :watchSlidesProgress="false"
        :slideToClickedSlide="true"
        @swiper="setSecondSwiper"
      >
        <swiper-slide
          class="swiper__thumb"
          v-for="(thumb, thumbIndex) in slds.thumbs"
          :key="thumbIndex"
        >
          <img class="swiper__thumb-img" :src="thumb" :alt="thumbIndex" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from "vue";
import { Navigation, Controller, Mousewheel, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    slides: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const firstSwiper = ref(null);
    const secondSwiper = ref(null);
    const setFirstSwiper = (swiper) => {
      firstSwiper.value = swiper;
    };
    const setSecondSwiper = (swiper) => {
      secondSwiper.value = swiper;
    };

    const slds = toRef(props, "slides");

    // async await this.$axios()
    // => slds = sucsess result
    return {
      Lazy,
      Controller,
      firstSwiper,
      secondSwiper,
      setFirstSwiper,
      setSecondSwiper,
      Navigation,
      Mousewheel,
      slds
    };
  },
  data() {
    return {
      //slds: null
    };
  },
  computed: {},
  mounted() {
    // this.$axios()
    // => this.slds = sucsess result
  },
  methods: {
    // closePP() {
    //   this.$parent.slides = null;
    //   // this.closeF();
    // }
  }
};
</script>

<style lang="less">
.pp {
  display: block;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: @bg;
  padding: 50px 0;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media @bh1000 {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
  &__close-btn {
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 1000;
    @media @bw1440 {
      right: 30px;
    }
    @media @bw768 {
      right: 20px;
    }
    @media @bh1000 {
      top: 5vh;
    }
  }
  &__container {
    .container;
    flex-shrink: 0;
  }
  &__thumbs-container {
    width: 100%;
  }
}
</style>
