<template>
  <main class="app__page design">
    <div class="design__container">
      <h1 class="design__title title title--bb">
        {{ $t("pages.design.title") }}
      </h1>
    </div>
    <div v-if="pageLocale" class="design__content-wrapper">
      <div class="design__bg-img bg-img"></div>
      <div class="design__semicircles"></div>

      <div class="design__container">
        <p class="design__main-text is-observable">
          <span class="d-block observe-target observe-80-bottom">
            {{ pageLocale.main_text }}
          </span>
        </p>
      </div>

      <div class="design__block">
        <div class="design__semicircles-right"></div>
        <div class="design__container">
          <h3 class="design__sub-title design__sub-title--mb40">
            {{ pageLocale.concept.title }}
          </h3>
          <div class="design__sub-block1">
            <div class="design__item design__item--mr50">
              <div class="design__img-wrap">
                <img
                  class="design__img design__img--mb20 design__img--cursor"
                  src="/img/design/design1.jpg"
                  alt="design1"
                  @click="openPP('1')"
                />
              </div>
              <button
                type="button"
                class="design__see-all"
                @click="openPP('1')"
              >
                {{ pageLocale.see_all }}
              </button>
            </div>
            <p class="design__text design__text--1 is-observable">
              <span class="d-block observe-target observe-40-right">
                {{ pageLocale.concept.text }}
              </span>
            </p>
          </div>
          <div class="design__words-mobile">
            <img
              src="/img/design/words2.svg"
              class="design__words2"
              alt="words"
            />
          </div>
          <div class="design__sub-block2">
            <div class="design__img-wrap2 is-observable">
              <img
                src="/img/design/design2.jpg"
                class="design__img observe-target observe-80-bottom"
                alt="design2"
              />
            </div>
            <div class="design__text design__text--2 is-observable">
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.concept.text2 }}
              </span>

              <ul class="design__list">
                <li class="design__list-li observe-target observe-80-bottom">
                  {{ pageLocale.concept.list[0] }}
                </li>
                <li class="design__list-li observe-target observe-80-bottom">
                  {{ pageLocale.concept.list[1] }}
                </li>
                <li class="design__list-li observe-target observe-80-bottom">
                  {{ pageLocale.concept.list[2] }}
                </li>
                <li class="design__list-li observe-target observe-80-bottom">
                  {{ pageLocale.concept.list[3] }}
                </li>
                <li class="design__list-li observe-target observe-80-bottom">
                  {{ pageLocale.concept.list[4] }}
                </li>
                <li class="design__list-li observe-target observe-80-bottom">
                  {{ pageLocale.concept.list[5] }}
                </li>
              </ul>
            </div>
            <div class="design__img-wrap3 is-observable">
              <img
                src="/img/design/design3.jpg"
                class="design__img observe-target observe-80-bottom"
                alt="design3"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="design__words-wrap">
        <img src="/img/design/words.svg" class="design__words" alt="words" />
      </div>

      <div class="design__block2">
        <div class="design__rectangle"></div>
        <div class="design__container">
          <div class="design__block2-grid">
            <h3 class="design__sub-title design__sub-title--mb20-mobile">
              {{ pageLocale.style.title }}
            </h3>
            <p class="design__text design__text--3 is-observable">
              <span class="d-block observe-target observe-40-right">
                {{ pageLocale.style.text }}
              </span>
            </p>
            <div class="design__item design__item--w470">
              <div class="design__img-wrap4 is-observable">
                <img
                  class="
                    design__img design__img--mb20 design__img--cursor
                    observe-target observe-40-left
                  "
                  src="/img/design/design4.jpg"
                  alt="design4 "
                  @click="openPP('2')"
                />
              </div>
              <button
                type="button"
                class="design__see-all"
                @click="openPP('2')"
              >
                {{ pageLocale.see_all }}
              </button>
            </div>
            <p class="design__text design__text--4 is-observable">
              <span class="d-block observe-target observe-60-bottom">
                {{ pageLocale.style.text2 }}
              </span>
            </p>
            <div class="design__imgs">
              <div class="design__img-wrap5 is-observable">
                <img
                  class="design__img observe-target observe-60-bottom"
                  src="/img/design/design5.jpg"
                  alt="design5"
                />
              </div>
              <div class="design__img-wrap6 is-observable">
                <img
                  class="design__img observe-target observe-60-bottom"
                  src="/img/design/design6.jpg"
                  alt="design6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="design__block3">
        <div class="design__block3-rectangle"></div>
        <div class="design__container">
          <div class="design__block3-grid2">
            <h2
              class="
                design__title-h2 design__title-h2--mb40 design__title-h2--w520
                is-observable
              "
            >
              <span class="d-block observe-target observe-40-left">
                Заголовок–пояснение к разделу с дизайном квартир
              </span>
            </h2>
            <div class="design__img-wrap7">
              <img
                class="design__img"
                src="/img/design/design7.jpg"
                alt="design7"
              />
            </div>
            <p class="design__text design__text--5 is-observable">
              <span class="d-block observe-target observe-60-bottom">
                Я думаю здесь будет содержатся текст поясняющий суть раздела или
                какие-то вводные слова, чтобы пользователь понимал на какую
                информацию он может рассчитывать, посетив данный раздел.
              </span>
            </p>
          </div>
          <div class="design__block3-wrap">
            <div class="design__block3-inner">
              <div class="design__block3-item">
                <h3 class="design__sub-title design__sub-title--mb20">
                  Скандинавский стиль
                </h3>
                <p class="design__text design__text--6 is-observable">
                  <span class="d-block observe-target observe-60-bottom">
                    Согласно ставшей уже классической работе Филипа Котлера,
                    ценовая стратегия неверно создает сублимированный
                    SWOT-анализ, не считаясь с затратами.
                  </span>
                </p>
              </div>
              <div class="design__item">
                <div class="design__img-wrap">
                  <img
                    class="design__img design__img--mb20 design__img--cursor"
                    src="/img/design/design8.jpg"
                    alt="design8"
                    @click="openPP('3')"
                  />
                </div>
                <button
                  type="button"
                  class="design__see-all"
                  @click="openPP('3')"
                >
                  Смотреть все фото
                </button>
              </div>
            </div>
            <div class="design__block3-inner2">
              <div class="design__img-wrap9 is-observable">
                <img
                  src="/img/design/design9.jpg"
                  class="design__img observe-target observe-40-left"
                  alt="design9"
                />
              </div>
              <p class="design__text design__text--7 is-observable">
                <span class="d-block observe-target observe-60-bottom">
                  Доступ к кладовым осуществляется как со скоростного, так и с
                  грузового лифтов.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="design__block4">
        <div class="design__block4-semicircles"></div>
        <div class="design__container">
          <div class="design__block4-grid3">
            <h2
              class="
                design__sub-title
                design__sub-title--mb20-mobile
                design__sub-title--ml90
              "
            >
              Стиль лофт
            </h2>
            <div class="design__item design__item--w470">
              <div class="design__img-wrap10">
                <img
                  class="design__img design__img--mb20 design__img--cursor"
                  src="/img/design/design10.jpg"
                  alt="design10"
                  @click="openPP('4')"
                />
              </div>
              <button
                type="button"
                class="design__see-all"
                @click="openPP('4')"
              >
                Смотреть все фото
              </button>
            </div>
            <p class="design__text design__text--8 is-observable">
              <span class="d-block observe-target observe-60-bottom">
                На цокольных этажах ЖК Panorama Residence располагаются удобные
                кладовые разной площади — разместить в них можно любые ваши
                вещи. Все, от спортивного инвентаря до мягких игрушек —
                циркуляция воздуха позволяет хранить любой тип предметов в
                комфортных условиях.
              </span>
            </p>
            <div class="design__grid3-imgs">
              <div class="design__img-wrap11 is-observable">
                <img
                  class="design__img observe-target observe-60-bottom"
                  src="/img/design/design11.jpg"
                  alt="design11"
                />
              </div>
              <div class="design__img-wrap12 is-observable">
                <img
                  class="design__img observe-target observe-40-right"
                  src="/img/design/design12.jpg"
                  alt="design12"
                />
              </div>
            </div>
            <p class="design__text design__text--9 is-observable">
              <span class="d-block observe-target observe-60-bottom">
                Доступ к кладовым осуществляется как со скоростного, так и с
                грузового лифтов в комфортных условиях.
              </span>
            </p>
          </div>
          <div class="design__block4-grid4">
            <h2
              class="
                design__sub-title
                design__sub-title--mb20-mobile
                design__sub-title--mt65
              "
            >
              Минимализм
            </h2>
            <p class="design__text design__text--10 is-observable">
              <span class="d-block observe-target observe-40-left">
                Согласно ставшей уже классической работе Филипа Котлера, ценовая
                стратегия неверно создает сублимированный SWOT-анализ, не
                считаясь с затратами.
              </span>
            </p>
            <div class="design__item design__item--mb40 design__item--ml0">
              <div class="design__img-wrap13">
                <img
                  class="design__img design__img--mb20 design__img--cursor"
                  src="/img/design/design13.jpg"
                  alt="design13"
                  @click="openPP('5')"
                />
              </div>
              <button
                type="button"
                class="design__see-all"
                @click="openPP('5')"
              >
                Смотреть все фото
              </button>
            </div>
            <p class="design__text design__text--11 is-observable">
              <span class="d-block observe-target observe-60-bottom">
                Согласно ставшей уже классической работе Филипа Котлера, ценовая
                стратегия неверно создает.
              </span>
            </p>
          </div>
          <div class="design__inner">
            <div class="design__img-wrap14">
              <img
                class="design__img"
                src="/img/design/design14.jpg"
                alt="design14"
              />
            </div>
            <p class="design__text design__text--12 is-observable">
              <span class="d-block observe-target observe-40-right">
                На цокольных этажах ЖК Panorama Residence располагаются удобные
                кладовые разной площади — разместить в них можно любые ваши
                вещи.
              </span>
            </p>
          </div>
          <div class="design__questions">
            <h2 class="design__questions-title is-observable">
              <span
                class="
                  design__questions-word
                  d-block
                  observe-target observe-40-left
                "
                >Остались</span
              >
              <span
                class="
                  design__questions-word
                  d-block
                  observe-target observe-40-left
                "
                >вопросы?</span
              >
            </h2>
            <button
              type="button"
              class="design__questions-btn btn"
              @click="openCallMe"
            >
              {{ $t("callme.submit") }}
            </button>
          </div>
        </div>
      </div> -->
    </div>

    <div class="design__container-2500">
      <localized-link to="/improvement" class="next-page">
        <span class="next-page__capiton">{{ $t("next") }}</span>
        <span class="next-page__name">
          <span class="next-page__title">
            {{ $t("pages.improvement.title") }}
          </span>
          <span class="next-page__btn r-btn">
            <svg-icon
              width="14"
              height="10"
              name="arrow_right"
              class="btn-icon"
            />
          </span>
        </span>
      </localized-link>
    </div>

    <PageFooter />
    <PopUp v-if="slides" :slides="slides" :close="closePP" />
  </main>
</template>
<script>
import { pageLocale } from "@/mixins/pageLocale.js";
import { observer } from "@/mixins/observer.js";
import PageFooter from "@/components/PageFooter";
import PopUp from "@/components/PopUp";

export default {
  name: "design",
  components: { PageFooter, PopUp },
  mixins: [pageLocale, observer],
  data() {
    return {
      slides: undefined
    };
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    sliders() {
      return this.$store.state.sliders;
    }
  },
  methods: {
    openPP(key) {
      const slides = this.sliders[key];
      if (slides && slides.images && !slides.images.length) return;

      this.slides = slides;
      if (this.slides) return;

      this.$axios({
        method: "get",
        url: this.api + "/sliders/" + key,
        timeout: 90000
      })
        .then((response) => {
          if (response && response.data) {
            this.$store.state.sliders[key] = response.data;
            this.openPP(key);
          } else {
            this.$toast.error(this.$t("error"));
          }
        })
        .catch((error) => {
          this.$toast.error(this.$t("error"));
          if (error && error.response) console.log(error.response);
        });
    },
    closePP() {
      this.slides = null;
    },
    openCallMe() {
      this.$store.state.callMeActive = true;
    }
  }
};
</script>
<style lang="less">
.design {
  .padding-page-top;
  position: relative;
  overflow: hidden;
  &__semicircles {
    position: absolute;
    width: 225px;
    height: 402px;
    overflow: hidden;
    pointer-events: none;
    @media @bw1440 {
      width: 130px;
      height: 271px;
    }
    @media @bw1170 {
      width: 56px;
      height: 170px;
    }
    @media @bw768 {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      box-sizing: border-box;
      width: 380px;
      height: 380px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
      @media @bw1440 {
        width: 247px;
        height: 255px;
      }
      @media @bw1170 {
        width: 170px;
        height: 170px;
      }
    }
    &::before {
      bottom: 0;
      right: 0;
      border: 1px solid @white;
    }
    &::after {
      top: 0px;
      right: 60px;
      border: 1px solid @gold;
      @media @bw1440 {
        right: 40px;
      }
      @media @bw1170 {
        top: -9px;
        right: 26px;
      }
    }
  }
  &__semicircles-right {
    position: absolute;
    right: 0;
    bottom: 130px;
    width: 140px;
    height: 336px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
    @media @bw1440 {
      width: 53px;
      height: 255px;
    }
    @media @bw1170 {
      display: none;
    }
    @media @bw768 {
      display: block;
      right: auto;
      bottom: 115px;
      width: 51px;
      height: 102px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      box-sizing: border-box;
      width: 308px;
      height: 308px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
      @media @bw1440 {
        width: 257px;
        height: 255px;
      }
      @media @bw768 {
        width: 118px;
        height: 102px;
      }
    }
    &::before {
      left: 0;
      top: 0;
      border: 1px solid @gold;
      @media @bw768 {
        right: 15px;
        left: auto;
      }
    }
    &::after {
      bottom: 0;
      left: 35px;
      border: 1px solid @white;
      @media @bw768 {
        left: auto;
        right: 0;
      }
    }
  }
  &__container {
    .container;
  }
  &__main-text {
    margin: 0 0 100px 260px;
    max-width: 650px;
    width: 100%;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    @media @bw1440 {
      margin: 0 0 100px 150px;
    }
    @media @bw1170 {
      margin: 0 0 100px 70px;
      max-width: 425px;
      font-size: 24px;
    }
    @media @bw768 {
      margin: 0 0 80px 25px;
      max-width: 350px;
      font-size: 20px;
    }
    @media @bw500 {
      margin: 0 0 80px;
      box-sizing: border-box;
      max-width: 100%;
      padding-left: 25px;
    }
    @media @bw400 {
      margin: 0 0 60px;
      padding-left: 0;
      font-weight: 500;
      font-size: 16px;
    }
  }
  &__block {
    position: relative;
    @media @bw1170 {
      margin-bottom: 120px;
    }
    @media @bw768 {
      margin-bottom: 100px;
    }
    &::before {
      display: none;
      @media @bw1170 {
        display: block;
        content: "";
        position: absolute;
        background-image: url("~@/assets/images/words2.svg");
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        bottom: 120px;
        margin: 0 auto;
        width: 625px;
        height: 234px;
      }
      @media @bw768 {
        display: none;
      }
    }
  }
  &__words-wrap {
    margin-top: -150px;
    position: relative;
    height: 260px;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    pointer-events: none;
    @media @bw1440 {
      margin-top: -30px;
      margin-bottom: 10px;
      height: 176px;
    }
    @media @bw1170 {
      display: none;
    }
  }
  &__words {
    position: absolute;
    top: 0;
    width: 2505px;
    height: 100%;
    @media @bw1440 {
      width: 1632px;
    }
  }
  &__words-mobile {
    display: none;
    @media @bw768 {
      display: block;
      position: relative;
      margin: 0 -20px;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      padding-top: 38.64%;
    }
  }
  &__words2 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
  }
  &__sub-block1 {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
    @media @bw1440 {
      margin-bottom: 10px;
    }
    @media @bw1170 {
      margin-bottom: 30px;
    }
    @media @bw768 {
      flex-direction: column;
      margin-bottom: 0px;
    }
  }
  &__sub-title {
    margin: 0;
    grid-area: design__sub-title;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    @media @bw1170 {
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 16px;
    }
    &--mb40 {
      margin-bottom: 40px;
      @media @bw768 {
        margin-bottom: 30px;
      }
    }
    &--mb20-mobile {
      @media @bw768 {
        margin-bottom: 20px;
      }
    }
    &--mb20 {
      margin-bottom: 20px;
    }
    &--ml90 {
      @media @bw1440 {
        margin-left: 90px;
      }
      @media @bw1170 {
        margin-left: 0;
      }
    }
    &--mt65 {
      margin-top: 65px;
      @media @bw1440 {
        margin-top: 0;
      }
    }
  }
  &__item {
    max-width: 850px;
    width: 100%;
    grid-area: design__item;
    @media @bw1440 {
      max-width: 662px;
    }
    @media @bw1170 {
      margin-left: 40px;
    }
    @media @bw768 {
      margin-left: 0px;
    }
    &--ml0 {
      @media @bw1170 {
        margin-left: 0px;
      }
      @media @bw768 {
        margin-left: 0px;
      }
    }
    &--mr50 {
      margin-right: 50px;
      @media @bw1440 {
        margin-right: 30px;
      }
      @media @bw768 {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
    &--w470 {
      max-width: 470px;
      @media @bw1440 {
        max-width: 320px;
      }
      @media @bw1170 {
        margin-left: 0;
        max-width: 285px;
      }
      @media @bw768 {
        margin-bottom: 40px;
        max-width: 230px;
      }
      @media @bw400 {
        max-width: 200px;
      }
    }
    &--mb40 {
      @media @bw768 {
        margin-bottom: 40px;
      }
    }
  }
  &__img {
    display: block;
    width: 100%;
    object-fit: contain;
    box-shadow: 0px 40px 40px rgba(51, 57, 66, 0.6);
    &--mb20 {
      margin-bottom: 20px;
    }
    &--cursor {
      cursor: pointer;
    }
  }
  &__see-all {
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    color: @white_60;
    transition: color 0.2s;
    &:active {
      color: @gold;
    }
    &:hover {
      @media (hover: hover) {
        color: @gold;
      }
    }
  }
  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    &--1 {
      margin-top: 50px;
      max-width: 270px;
      width: 100%;
      @media @bw1440 {
        max-width: 228px;
      }
      @media @bw1170 {
        margin-top: 10px;
      }
      @media @bw768 {
        margin-top: 0;
        align-self: center;
      }
    }
    &--2 {
      margin-top: 30px;
      margin-right: 88px;
      align-self: center;
      max-width: 440px;
      width: 100%;
      @media @bw1440 {
        align-self: flex-end;
        margin: 0 56px 30px 0;
        max-width: 346px;
      }
      @media @bw1170 {
        order: 2;
        margin: 0;
        max-width: 425px;
      }
      @media @bw768 {
        box-sizing: border-box;
        margin: 0 0 40px;
        max-width: 320px;
      }
      @media @bw500 {
        padding-left: 55px;
        max-width: 100%;
      }
      @media @bw400 {
        padding-left: 0;
      }
    }
    &--3 {
      grid-area: design__text--3;
      max-width: 445px;
      @media @bw768 {
        margin-bottom: 40px;
      }
    }
    &--4 {
      grid-area: design__text--4;
      max-width: 320px;
      @media @bw768 {
        margin-bottom: 40px;
        box-sizing: border-box;
        padding-left: 70px;
        max-width: 100%;
      }
      @media @bw400 {
        padding-left: 30px;
      }
    }
    &--5 {
      grid-area: design__text--5;
      margin-left: auto;
      margin-right: 40px;
      max-width: 410px;
      @media @bw1440 {
        margin-right: auto;
      }
      @media @bw1170 {
        max-width: 345px;
      }
      @media @bw960 {
        margin-right: 0;
      }
      @media @bw768 {
        box-sizing: border-box;
        padding-right: 40px;
        max-width: 100%;
      }
      @media @bw500 {
      }
      @media @bw400 {
        padding-right: 0;
      }
    }
    &--6 {
      @media @bw768 {
        margin-bottom: 40px;
      }
    }
    &--7 {
      max-width: 400px;
      width: 100%;
      @media @bw1170 {
        max-width: 360px;
      }
      @media @bw768 {
        margin-left: 70px;
        max-width: 425px;
      }
      @media @bw600 {
        box-sizing: border-box;
        margin-left: 0;
        padding-left: 70px;
        max-width: 100%;
      }
      @media @bw400 {
        padding-left: 0;
        text-align: right;
      }
    }
    &--8 {
      grid-area: design__text--8;
      max-width: 470px;
      width: 100%;
      @media @bw1440 {
        margin-left: 90px;
      }
      @media @bw1170 {
        margin-left: 0;
      }
      @media @bw768 {
        margin-bottom: 40px;
      }
      @media @bw600 {
        box-sizing: border-box;
        padding-right: 30px;
        max-width: 100%;
      }
      @media @bw400 {
        padding-right: 0;
      }
    }
    &--9 {
      grid-area: design__text--9;
      max-width: 291px;
      width: 100%;
      @media @bw1440 {
        margin-left: 90px;
      }
      @media @bw1170 {
        margin-left: 0;
      }
      @media @bw768 {
        margin-left: auto;
        margin-bottom: 40px;
      }
      @media @bw400 {
        box-sizing: border-box;
        padding-left: 20px;
        max-width: 100%;
      }
    }
    &--10 {
      grid-area: design__text--10;
      @media @bw768 {
        margin-bottom: 40px;
        box-sizing: border-box;
        max-width: 100%;
        padding-right: 70px;
      }
      @media @bw400 {
        padding-right: 30px;
      }
    }
    &--11 {
      grid-area: design__text--11;
      @media @bw1020 {
        max-width: 450px;
        width: 100%;
      }
      @media @bw600 {
        box-sizing: border-box;
        max-width: 100%;
        padding-right: 70px;
      }
      @media @bw400 {
        padding-right: 30px;
      }
    }
    &--12 {
      margin: 0 auto;
      max-width: 415px;
      width: 100%;
      @media @bw1440 {
        margin-right: 70px;
      }
      @media @bw1170 {
        margin: 15px 0 0 120px;
        max-width: 355px;
      }
      @media @bw768 {
        margin: 0 0 0 70px;
        max-width: 380px;
      }
      @media @bw500 {
        margin: 0;
        box-sizing: border-box;
        padding-left: 70px;
        max-width: 100%;
      }
      @media @bw400 {
        padding-left: 30px;
      }
    }
  }
  &__sub-block2 {
    display: flex;
    @media @bw1170 {
      flex-direction: column;
      position: relative;
      padding-left: 220px;
    }
    @media @bw768 {
      padding-left: 0;
      margin-top: -180px;
    }
    @media @bw600 {
      margin-top: -120px;
    }
    @media @bw400 {
      margin-top: -75px;
    }
  }
  &__img-wrap2 {
    margin-top: 30px;
    margin-right: 55px;
    max-width: 416px;
    width: 100%;
    @media @bw1440 {
      margin-top: 70px;
      max-width: 250px;
    }
    @media @bw1170 {
      position: absolute;
      top: 70px;
      left: 0;
      margin: 0;
      max-width: 220px;
    }
    @media @bw768 {
      position: static;
      align-self: flex-start;
      margin-bottom: 40px;
      order: 1;
    }
    @media @bw400 {
      max-width: 175px;
    }
  }
  &__img-wrap3 {
    max-width: 320px;
    width: 100%;
    @media @bw1440 {
      max-width: 260px;
    }
    @media @bw1170 {
      order: 1;
      align-self: flex-end;
      margin-bottom: 50px;
      max-width: 265px;
    }
    @media @bw768 {
      order: 3;
    }
    @media @bw400 {
      max-width: 250px;
    }
  }
  &__block2 {
    position: relative;
    margin-bottom: 150px;
    @media @bw1440 {
      margin-bottom: 160px;
    }
    @media @bw1170 {
      margin-bottom: 120px;
    }
    @media @bw768 {
      margin-bottom: 100px;
    }
    @media @bw400 {
      margin-bottom: 80px;
    }
  }
  &__rectangle {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 270px;
    pointer-events: none;
    @media @bw1170 {
      bottom: 160px;
      width: 10px;
      height: 140px;
    }
    @media @bw768 {
      display: none;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: @gold;
    }
  }
  &__block2-grid {
    display: grid;
    grid-template-columns: 470px 660px;
    grid-template-rows: max-content min-content;
    gap: 20px 190px;
    grid-template-areas:
      "design__item design__sub-title"
      "design__item design__text--3"
      "design__item design__text--4"
      "design__item design__imgs";
    align-items: flex-start;
    @media @bw1440 {
      gap: 20px 85px;
      grid-template-columns: 310px 574px;
    }
    @media @bw1170 {
      position: relative;
      gap: 20px 70px;
      grid-template-columns: 284px 550px;
      padding-bottom: 100px;
    }
    @media @bw1020 {
      grid-template-columns: 284px 1fr;
    }
    @media @bw768 {
      display: block;
      padding-bottom: 0px;
    }
  }
  &__imgs {
    grid-area: design__imgs;
    display: flex;
    margin-top: 20px;
    @media @bw1440 {
      margin-top: 10px;
    }
    @media @bw1170 {
      margin-top: 65px;
    }
    @media @bw768 {
      margin-top: 0;
      flex-direction: column;
    }
  }
  &__img-wrap5 {
    margin-top: 165px;
    margin-right: 75px;
    max-width: 270px;
    width: 100%;
    @media @bw1440 {
      margin-top: 85px;
      max-width: 230px;
    }
    @media @bw1170 {
      position: absolute;
      left: 160px;
      bottom: 0;
      max-width: 200px;
    }
    @media @bw768 {
      position: static;
      margin-top: 0;
      margin-bottom: 40px;
    }
    @media @bw400 {
      max-width: 160px;
    }
  }
  &__img-wrap6 {
    max-width: 320px;
    width: 100%;
    @media @bw1440 {
      max-width: 260px;
    }
    @media @bw1170 {
      margin-left: auto;
      max-width: 265px;
    }
    @media @bw400 {
      max-width: 210px;
    }
  }
  &__block3-grid2 {
    display: grid;
    grid-template-columns: 1fr 477px;
    grid-template-rows: min-content max-content;
    gap: 40px 185px;
    grid-template-areas:
      "design__title-h2 design__img-wrap7"
      "design__text--5 design__img-wrap7"
      "... design__img-wrap7";
    align-items: flex-start;
    margin-bottom: 150px;
    @media @bw1440 {
      grid-template-columns: 1fr 347px;
      gap: 40px 100px;
    }
    @media @bw1170 {
      grid-template-columns: 1.2fr 0.8fr;
      gap: 30px 63px;
      margin-bottom: 125px;
    }
    @media @bw768 {
      display: block;
      margin-bottom: 100px;
    }
    @media @bw400 {
      margin-bottom: 80px;
    }
  }
  &__title-h2 {
    grid-area: design__title-h2;
    margin: 0;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    @media @bw1170 {
      font-size: 24px;
    }
    @media @bw768 {
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 18px;
    }
    &--w520 {
      margin-left: auto;
      max-width: 520px;
      width: 100%;
      @media @bw1440 {
        margin-left: 0;
      }
      @media @bw1170 {
        max-width: 350px;
      }
    }
    &--mb40 {
      @media @bw768 {
        margin-bottom: 40px;
      }
      @media @bw400 {
        margin-bottom: 30px;
      }
    }
  }
  &__img-wrap7 {
    grid-area: design__img-wrap7;
    @media @bw1170 {
      max-width: 265px;
      width: 100%;
    }
    @media @bw768 {
      margin: 0 auto 40px;
    }
    @media @bw600 {
      margin-right: 0;
    }
    @media @bw400 {
      max-width: 250px;
    }
  }
  &__block3 {
    position: relative;
    margin-bottom: 150px;
    @media @bw1170 {
      margin-bottom: 120px;
    }
    @media @bw768 {
      margin-bottom: 100px;
    }
    @media @bw400 {
      margin-bottom: 80px;
    }
  }
  &__block3-rectangle {
    position: absolute;
    bottom: 70px;
    right: 0;
    width: 23px;
    height: 270px;
    background-color: @gold;
    pointer-events: none;
    @media @bw1440 {
      bottom: 0;
      width: 15px;
    }
    @media @bw1170 {
      display: none;
    }
  }
  &__block3-inner {
    display: flex;
    @media @bw768 {
      flex-direction: column;
    }
  }
  &__block3-item {
    margin-right: auto;
    max-width: 310px;
    width: 100%;
    @media @bw1440 {
      max-width: 270px;
    }
    @media @bw1170 {
      max-width: 232px;
    }
    @media @bw768 {
      max-width: 374px;
    }
  }
  &__block3-inner2 {
    display: flex;
    align-items: flex-end;
    margin-top: -100px;
    @media @bw1440 {
      margin-top: -20px;
    }
    @media @bw1170 {
      margin-top: 30px;
    }
    @media @bw768 {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 40px;
    }
  }
  &__img-wrap9 {
    margin-right: 95px;
    max-width: 376px;
    width: 100%;
    @media @bw1440 {
      margin-right: 60px;
      max-width: 250px;
    }
    @media @bw1170 {
      margin-right: 40px;
      max-width: 232px;
    }
    @media @bw768 {
      margin-bottom: 40px;
      max-width: 200px;
    }
    @media @bw400 {
      max-width: 165px;
    }
  }
  &__block4 {
    position: relative;
    margin-bottom: 200px;
    @media @bw1170 {
      margin-bottom: 150px;
    }
    @media @bw768 {
      margin-bottom: 130px;
    }
    @media @bw400 {
      margin-bottom: 100px;
    }
  }
  &__block4-semicircles {
    position: absolute;
    top: 450px;
    left: 0;
    width: 140px;
    height: 380px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
    @media @bw1440 {
      top: 32%;
      width: 85px;
      height: 260px;
    }
    @media @bw1170 {
      width: 67px;
      height: 170px;
    }
    @media @bw1020 {
      top: 400px;
    }
    @media @bw768 {
      top: 27%;
      width: 56px;
      height: 107px;
    }
    @media @bw400 {
      width: 40px;
      height: 88px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 380px;
      height: 380px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @gold;
      @media @bw1440 {
        width: 255px;
        height: 255px;
      }
      @media @bw1170 {
        width: 169px;
        height: 169px;
      }
      @media @bw768 {
        width: 101px;
        height: 101px;
      }
      @media @bw400 {
        width: 83px;
        height: 85px;
      }
    }
    &::before {
      right: 60px;
      top: -20px;
      @media @bw1440 {
        top: -13px;
        right: 40px;
      }
      @media @bw1020 {
        top: -10px;
        right: 26px;
      }
      @media @bw768 {
        top: -1px;
        right: 15px;
      }
      @media @bw400 {
        top: -4px;
        right: 15px;
      }
    }
    &::after {
      border: 1px solid @white;
      right: 0;
      bottom: 0;
    }
  }
  &__block4-grid3 {
    display: grid;
    grid-template-columns: 470px 1fr;
    grid-template-rows: max-content min-content;
    gap: 20px 190px;
    grid-template-areas:
      "design__item design__sub-title"
      "design__item design__text--8"
      "design__item design__text--9"
      "design__item design__grid3-imgs";
    align-items: flex-start;
    margin-bottom: 150px;
    @media @bw1440 {
      grid-template-columns: 310px 1fr;
      gap: 20px 85px;
    }
    @media @bw1170 {
      gap: 20px 70px;
      grid-template-areas:
        "design__item design__sub-title"
        "design__item design__text--8"
        "design__item design__text--9"
        "design__grid3-imgs design__grid3-imgs";
      grid-template-rows: min-content min-content;
      margin-bottom: 120px;
    }
    @media @bw1020 {
      grid-template-columns: 284px 1fr;
    }
    @media @bw768 {
      margin-bottom: 100px;
      display: block;
      position: relative;
      padding-bottom: 182px;
    }
    @media @bw400 {
      margin-bottom: 80px;
      padding-bottom: 156px;
    }
  }
  &__grid3-imgs {
    grid-area: design__grid3-imgs;
    display: flex;
    @media @bw1170 {
      margin: 0 30px 0 auto;
    }
    @media @bw768 {
      margin: 0 0 40px;
    }
  }
  &__img-wrap11 {
    margin-top: 150px;
    margin-right: 73px;
    max-width: 267px;
    width: 100%;
    @media @bw1440 {
      margin-right: 85px;
      max-width: 230px;
    }
    @media @bw1170 {
      margin-top: 50px;
      margin-right: 90px;
      max-width: 200px;
    }
    @media @bw768 {
      position: absolute;
      left: 0;
      bottom: 0;
      max-width: 245px;
    }
    @media @bw400 {
      max-width: 210px;
    }
  }
  &__img-wrap12 {
    max-width: 320px;
    width: 100%;
    @media @bw1440 {
      max-width: 258px;
    }
    @media @bw1170 {
      max-width: 230px;
    }
    @media @bw768 {
      margin-left: auto;
    }
    @media @bw400 {
      max-width: 182px;
    }
  }
  &__block4-grid4 {
    display: grid;
    grid-template-columns: 1fr 850px;
    grid-template-rows: auto auto;
    gap: 20px 150px;
    grid-template-areas:
      "design__sub-title design__item"
      "design__text--10 design__item"
      "design__text--11 design__item"
      "... design__item";
    align-items: start;
    align-content: start;
    margin-bottom: 30px;
    @media @bw1440 {
      grid-template-columns: 1fr 660px;
      gap: 20px 45px;
      margin-bottom: 20px;
    }
    @media @bw1020 {
      grid-template-areas:
        "design__sub-title design__item"
        "design__text--10 design__item"
        "... design__item"
        "design__text--11 design__text--11";
      grid-template-columns: 207px 1fr;
      gap: 20px 77px;
      margin-bottom: 50px;
    }
    @media @bw768 {
      display: block;
      margin-bottom: 40px;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    margin-bottom: 200px;
    @media @bw1170 {
      margin-bottom: 150px;
      align-items: flex-start;
    }
    @media @bw768 {
      margin-bottom: 130px;
      flex-direction: column;
    }
    @media @bw400 {
      margin-bottom: 100px;
    }
  }
  &__img-wrap14 {
    max-width: 375px;
    width: 100%;
    @media @bw1440 {
      max-width: 250px;
    }
    @media @bw1170 {
      max-width: 230px;
    }
    @media @bw768 {
      margin-bottom: 40px;
      max-width: 245px;
    }
    @media @bw400 {
      max-width: 165px;
    }
  }

  &__questions {
    display: flex;
    align-items: flex-end;
    @media @bw1020 {
      flex-direction: column;
      align-items: center;
    }
  }

  &__questions-title {
    margin: 0 130px 0 0;
    display: flex;
    flex-direction: column;
    font-family: Bebas Neue, sans-serif;
    font-weight: 700;
    font-size: 120px;
    line-height: 1;
    color: #f3f3f2;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    @media @bw1440 {
      margin: 0 88px 0 0;
      font-size: 90px;
    }
    @media @bw1020 {
      align-items: center;
      margin: 0 0 50px;
    }
    @media @bw768 {
      font-size: 48px;
    }
    @media @bw400 {
      margin: 0 0 30px;
      font-size: 36px;
    }
  }

  &__questions-word {
    & + & {
      margin-left: 145px;
      @media @bw1020 {
        margin-left: 0;
      }
    }
  }

  &__questions-btn {
    margin-bottom: 14px;
    width: 100%;
    @media @bw1440 {
      margin-bottom: 8px;
    }
    @media @bw1020 {
      margin-bottom: 0;
    }
  }

  &__container-2500 {
    .container-2500;
  }
}
</style>
